
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref<boolean>(false);
      const url = ref("");

    //Create form validation object
    const firstForm = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
    });

    const secondForm = Yup.object().shape({
      sessionCode: Yup.string()
        .required(t("validators_error.required"))
        .label("SessionCode"),
    });

    //Create form validation object
    const thirdForm = Yup.object().shape({
      sessionCode: Yup.string()
        .required(t("validators_error.required"))
        .label("SessionCode"),
    });

    const submit = (values: any) => {
      console.log(values);
      url.value = "https://www.asistan.ogzatech.com/pollster/3253543"
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
    });

    return {
      firstForm,
      secondForm,
      thirdForm,
      url,
      submit,

    };
  },
});
